export const locale = {
  ar: {
    'Login for administration': '',
    'Admin Email': '',
    'admin password': '',
    'Email is required!': '',
    'Password is required!':'',
    'Assessment Tool':'',
    'Please enter valid email address':'',
    'log in':'',
    'INVALID_EMAIL_OR_PASSWORD':'',
  },
};