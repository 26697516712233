import React from 'react'
import AuthHeader from '../AuthHeader'
import { useForm } from 'react-hook-form'
import { login } from '../../Components/action/common'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { _ } from '../../Components/action/locale'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoggedInData } from '../../redux/slices/admin'

function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [isLoader, setIsLoader] = useState(false)

  const onLoginSubmit = async (data) => {
    setIsLoader(true)
    let requestBody = {
      "email": data?.email,
      "password": data?.password
    }
    try {
      let response = await login(requestBody)
      setIsLoader(false);
      let userData = response?.data?.responseData;
      localStorage.setItem("token", userData?.token)
      dispatch(setLoggedInData(userData))
 

      // localStorage.setItem('timeToken',new Date())
      if(userData?.role == "SUPER_ADMIN"){
        navigate('/admin')
      }else{
        navigate('/assessment')
      }
      
    } catch ({ response }) {
      toast.dismiss()
      setIsLoader(false)
      toast.error(response?.data?.message)
    }
  }



  return (
    <>
      {/* <AuthHeader /> */}
      <div className="container admin_login login-wrpr">
        <div className="row">
            <div className="panel panel-default ">
              {/* <div className="panel-heading">{_('Login for administration')}</div> */}
              <a  className="logo"><img className="img-responsive" src="./images/logo_200x200.svg" /></a>
              <h3 className="text-dark text-center form-title">{_('Elite Speaker')}</h3>

              <div className="comman_response" />
              <div className="panel-body">
                <form className="form-horizontal" onSubmit={handleSubmit(onLoginSubmit)}>
                  <input type="hidden" name="_token" />
                  <div className="form-group">
                    <label htmlFor="email" className=" control-label">{_('Email')}</label>
                      <input className="form-control" name="email" {...register("email", {
                        required: {
                          value: true,
                          message: "Email is required!"
                        },
                        pattern: {
                          value:
                            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                          message: "Please enter valid email address",
                        },
                      })} />
                      <div className="error error_email" style={{ color: "red" }} >{errors?.email?.message}</div>
                   
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="control-label">{_('Password')}</label>
                      <input type="password" className="form-control" name="password" {...register("password", {
                        required: {
                          value: true,
                          message: "Password is required!"
                        },

                      })} />
                      <div className="error error_email" style={{ color: "red" }} >{errors?.password?.message}</div>

                  </div>
                  {/* <div className="form-group">
                    <div className="col-md-6 col-md-offset-4">
                      <div className="checkbox">
                        <label>
                          <input type="checkbox" name="remember" /> Remember me in the future!
                        </label>
                      </div>
                    </div>
                  </div> */}
                  <div className='forgot_wrpr'>
                    <a  className="primary_link">Forgot Password</a>
                    </div>
                  <div className="form-group ">
                    <div className=" p-static basic_wrpr ">

                      <button type="submit" className="btn btn-primary">
                        {
                          isLoader ? <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner> :<>
                          
                          <i className="fa fa-btn fa-sign-in" /> <span>{_('Login')}</span></>
                        }
                      </button>
                  

                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    </>

  )
}

export default Login