import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import StarRatings from 'react-star-ratings';
import { assessmentFeedback, fetchComment, fetchTagList } from '../../../Components/action/common';
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select';
import _ from 'lodash';

import './style.css'



function AssessmentEdit({ show, onHide, assessment, fetchAssessmentDetail, params }) {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [numberOfStar, setNumberOfStar] = useState(assessment?.rating)
  const [ratingNumber, setRatingNumber] = useState(assessment?.pigRating)
  const [refUserName, setRefUserName] = useState(assessment?.refUserName)
  const [commentValue, setCommentValue] = useState(null)
  const [isLoader, setIsLoader] = useState(false)


  useEffect(() => {
    console.log('aaaaaa', assessment)
    fetchCommentsList();
    getTags();
    if(assessment?.tags?.length > 0){
      let data = [];
      assessment?.tags?.forEach((obj, index) => {
        data.push({id: parseInt(obj)});
      })
      setSelectedTags(data);
    }
  }, [])

  const onSubmitFeedBack = async () => {
    setIsLoader(true)
    let requestBody = {
      "refUserId": assessment?.id,
      //"feedbackRating": numberOfStar,
      //"pigRating": parseInt(ratingNumber),
      "refUserName": refUserName,
      "tags": selectedTags
    }
    // console.log('requestBody', requestBody);
    // return;
    try {
      let response = await assessmentFeedback(requestBody)
      setIsLoader(false)
      fetchAssessmentDetail(params)
      onHide()
    } catch ({ response }) {
      setIsLoader(false)
    }
  }

  const fetchCommentsList = async () => {
    try {
      let response = await fetchComment({ "refUserId": assessment?.id })
      console.log(response?.data?.responseData, "response")
    } catch ({ response }) {

    }
  }
  const getTags = async () => {
    try {
      let response = await fetchTagList();
      let tags = response?.data?.responseData;
      console.log(response?.data?.responseData, "rrrrrrrr")
      setTagList(tags);
      let dd = [];
      // if(tags?.length > 0){
      //   dd = assessment?.tags?.filter((obj) => {
      //     // console.log('ddddddd1', obj);
      //     // return tags.indexOf(parseInt(obj)) !== -1
      //     return _.find(tags, (o) => {
      //       if(o.id == obj){
      //         return true
      //       }
      //     })
      //   })

        dd = tags?.filter((obj) => {
          if(assessment?.tags?.indexOf(obj.id?.toString()) !== -1){
            return true
          }
        })
        console.log('ddddd', dd)
        setSelectedTags(dd);
      
    } catch ({ response }) {

    }
  }

  

  // useEffect(() => {
  //   if (ratingNumber > 0) {
  //     for (let itr = 1; itr <= parseInt(ratingNumber); itr++) {
  //       const event = document.getElementById(itr.toString())
  //       event.style.backgroundImage = "url('/images/sheep-01.svg')"
  //       event.style.paddingBottom = "14px"
  //       // event.style.backgroundColor = 'orange'
  //     }
  //   }
  // }, [ratingNumber])

  // const setNewRating = (rating) => {
  //   setNumberOfStar(rating)
  //   console.log(rating, "rating")
  // }

  // function handleMouseOver(e) {
  //   for (let itr = 1; itr <= parseInt(e); itr++) {
  //     const event = document.getElementById(itr.toString())
  //     event.style.backgroundImage = "url('/images/sheep-01.svg')"
  //     event.style.paddingBottom = "14px"
  //     // event.style.backgroundColor = 'orange'

  //   }
  //   for (let itr = parseInt(e) + 1; itr <= parseInt(5); itr++) {
  //     const event = document.getElementById(itr.toString())
  //     event.style.backgroundImage = "url('/images/sheep.svg')"
  //     // event.style.backgroundColor = 'white'
  //   }

  // }

  // function handleOnMouseOut() {
  //   for (let itr = 1; itr <= parseInt(ratingNumber ? ratingNumber : 5); itr++) {
  //     const event = document.getElementById(itr.toString())
  //     event.style.backgroundImage = "url('/images/sheep-01.svg')"
  //     event.style.paddingBottom = "14px"
  //     // event.style.backgroundColor = 'orange'
  //   }
  //   for (let itr = (ratingNumber ? ratingNumber + 1 : 1); itr <= parseInt(5); itr++) {
  //     const event = document.getElementById(itr.toString())
  //     event.style.backgroundImage = "url('/images/sheep.svg')"
  //     // event.style.backgroundColor = 'white'
  //   }
  // }

  // function resetSheepImages() {
  //   for (let itr = 1; itr <= 5; itr++) {
  //     const event = document.getElementById(itr.toString())
  //     event.style.backgroundImage = "url('/images/sheep.svg')"
  //     // event.style.backgroundColor = 'white'
  //   }
  // }

  // function handleOnClick(e) {
  //   setRatingNumber(e)
  //   for (let itr = 1; itr <= e; itr++) {
  //     const event = document.getElementById(itr.toString())
  //     event.style.backgroundImage = "url('/images/sheep-01.svg')"
  //     event.style.paddingBottom = "14px"
  //     // event.style.backgroundColor = 'orange'
  //   }
  // }

  // const clearRating = (e, type) => {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   if (type == 'star') {
  //     setNumberOfStar(0);
  //   } else {
  //     setRatingNumber(0);
  //     resetSheepImages();

  //   }


  // }

  const updateTags = (obj) => {
    setSelectedTags(obj);

  }


  console.log('selectedTags', selectedTags)


  return (
    <Modal className="modala fade in" style={{ display: 'block', paddingLeft: '0px' }} show={show} onHide={() => { onHide() }} backdrop="static">

      <div className="modal-contenth" id="changePasswordPanel">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>×</font></font></span></button>
          <h4 className="modal-title" id="assignModalLabel"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Categories</font></font></h4>
        </div>
        <div className="modal-body formLog">
          <div className="comman_response" />
          <form className="changePasswordForm" onSubmit={handleSubmit(onSubmitFeedBack)}>
            <div className="voucher_outer">
              <div className="row">

                <div className="form-group">
                  <label className="control-label">Name</label>
                  <div>
                    <input type="text" className="form-control input-lg" name="name" defaultValue={assessment?.refUserName} onChange={(e) => setRefUserName(e.target.value)} />
                    <div className="error error_first_name" >{errors?.name?.message}</div>
                  </div>
                </div>

                {/* <input type="hidden" name="_token" /> */}
                {/* <div className="form-group ">
                  <div className="col-sm-8">
                    <label className="control-label"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Rating</font></font></label>
                    <span className='star-rating formStyle'>
                      <StarRatings
                        rating={numberOfStar}
                        starRatedColor="orange"
                        numberOfStars={5}
                        name='rating'
                        starHoverColor="orange"
                        changeRating={setNewRating}
                      />
                    </span>
                  </div>

                  <div className="col-sm-4" >
                    <a href="#" style={{ color: "#666", fontSize: '14px', marginTop: "27px", display: "inline-block" }} onClick={(e) => clearRating(e, 'star')}>Clear rating</a>
                  </div>
                </div> */}
                <div className="form-group">
                  {/* <div className="col-sm-8">
                    <label className="control-label"><font style={{ verticalAlign: 'inherit' }}>Sheep Rating</font></label>
                    <span className='sheep-rating formStyle'>
                      <img src='./images/sheep.svg' className='rating-hover' id="1" style={{ width: "27px", height: "40px", backgroundRepeat: "no-repeat", paddingBottom: '14px' }} onClick={() => handleOnClick('1')} alt="" onMouseOver={() => handleMouseOver("1")} onMouseOut={handleOnMouseOut} />
                      <img src='./images/sheep.svg' className='rating-hover' id="2" style={{ width: "27px", height: "40px", backgroundRepeat: "no-repeat", paddingBottom: '14px' }} onClick={() => handleOnClick('2')} alt="" onMouseOver={() => handleMouseOver("2")} onMouseOut={handleOnMouseOut} />
                      <img src='./images/sheep.svg' className='rating-hover' id="3" style={{ width: "27px", height: "40px", backgroundRepeat: "no-repeat", paddingBottom: '14px' }} onClick={() => handleOnClick('3')} alt="" onMouseOver={() => handleMouseOver("3")} onMouseOut={handleOnMouseOut} />
                      <img src='./images/sheep.svg' className='rating-hover' id="4" style={{ width: "27px", height: "40px", backgroundRepeat: "no-repeat", paddingBottom: '14px' }} onClick={() => handleOnClick('4')} alt="" onMouseOver={() => handleMouseOver("4")} onMouseOut={handleOnMouseOut} />
                      <img src='./images/sheep.svg' className='rating-hover' id="5" style={{ width: "27px", height: "40px", backgroundRepeat: "no-repeat", paddingBottom: '14px' }} onClick={() => handleOnClick('5')} alt="" onMouseOver={() => handleMouseOver("5")} onMouseOut={handleOnMouseOut} />

                    </span>
                  </div> */}
                  {/* <div className="col-sm-4" >
                    <a href="#" style={{ color: "#666", fontSize: '14px', marginTop: "27px", display: "inline-block" }} onClick={(e) => clearRating(e, 'sheep')}>Clear rating</a>
                  </div> */}
                </div>
                <div className="form-group" style={{ marginBottom: "10px", clear: "both", float: "left", width: "100%" }}>

                  <div className='col-sm-12'>
                    <label className="control-label"><font style={{ verticalAlign: 'inherit' }}>Tags</font></label>
                    <Select
                      getOptionLabel={(obj) => obj.name}
                      getOptionValue={(obj) => obj.id}
                      closeMenuOnSelect={false}
                      //components={animatedComponents}
                      value={selectedTags}
                      isMulti
                      options={tagList}
                      onChange={(obj) => {
                        updateTags(obj);
                      }}
                    />
                    
                  </div>
                </div>

                {/* <div className='form-group'>
                  <div className="col-sm-12">
                    <label className="control-label"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Comment</font></font></label>
                    <textarea className='formStyle' onChange={(e)=>setCommentValue(e?.target?.value)}></textarea>
                  </div>
                </div> */}
              </div>
            </div>
            <div >
              <button type="submit" className="btn btn-primary modelChangePasswordSave loadingbtns">{isLoader ? 'Loading...' : 'Save'}</button>
            </div>

          </form>
        </div>

      </div>
    </Modal>
  )
}

export default AssessmentEdit

