import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const initialState = {
  loggedInData:{},
  userListData: [],
  accountList: [],
  assessmentList: [],
  accounts: null,
  totalAssismentListData: [],
  comments: [],
  assessmentTitle: [],
  assessmentAccountList: [],
  viewAccountsDetails: [],
  commentList: []
}

const AdminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setLoggedInData(state, action) {
      state.loggedInData = action.payload
    },
    setUserListData(state, action) {
      state.userListData = action.payload
    },
    pushSetUserListData(state, action) {
      state.userListData = [...state.userListData, action.payload]
    },

    // account
    setAccountList(state, action) {
      state.accountList = action.payload
    },
    PushAccountList(state, action) {
      state.accountList = [...state.accountList, action.payload]
    },

    // assessment
    setAssessmentList(state, action) {
      state.assessmentList = action.payload
    },
    setAccounts(state, action) {
      state.accounts = action.payload
    },
    setTotalAssismentListData(state, action) {
      state.totalAssismentListData = action.payload
    },

    setComments(state, action) {
      state.comments = action.payload
    },

    setAssessmentTitle(state, action) {
      state.assessmentTitle = action.payload
    },
    setAssessmentAccountList(state, action) {
      state.assessmentAccountList = action.payload
    },
    setViewAccountsDetails(state, action) {
      state.viewAccountsDetails = action.payload
    },
    setCommentList(state, action) {
      state.fetchComments = action.payload
    }

  }
})


// reducers exports
export const { setLoggedInData, setCommentList, setUserListData, pushSetUserListData, PushAccountList, setAccountList, setAssessmentList, setAccounts, setTotalAssismentListData, setAssessmentTitle, setAssessmentAccountList, setViewAccountsDetails } = AdminSlice.actions;

export default AdminSlice.reducer;