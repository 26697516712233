import moment from "moment";

export const dateToFromNowDaily = ( myDate ) => {
  
  return myDate == moment().format('MM/DD/YYYY') 
                    ? 
                    moment( myDate ).format('[<span class="ac-today">Today</span> <span class="ac-date">]D MMMM[</span> <span classe="ac-day">]dddd[</span>]') 
                    : 
                    moment( myDate ).format('[<span class="ac-date">]D MMMM[</span> <span classe="ac-day">]dddd[</span>]');
}

export function authentication() {
  //console.log(typeof localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token') !== null,"typeof localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token') !== null)")
  // With auth token

  // return true
  if (typeof localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token') !== null)
    return true;
  else
    return false;
};

export function loadScript(id, src, attrs = {}, callback = null){  
  const existingScript = document.getElementById(id);  
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = src;    
    script.id = id;
    Object.keys(attrs).map(attr => {
      script.setAttribute(attr, attrs[attr]);
    });
    document.body.appendChild(script);    
    script.onload = () => { 
      if (callback) 
        callback();
    };
  }  
  if (existingScript && callback) 
    callback();
}