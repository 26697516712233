import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form'
import { createUser, updateUser } from '../../../Components/action/common';
import { useDispatch } from 'react-redux'
import { pushSetUserListData } from '../../../redux/slices/admin';
import { USER_ROLE } from '../../../Components/Common/constants';


function AddUser({ show, onHide, dataObj }) {
  const dispatch = useDispatch()
  const { register, handleSubmit, reset, formState: { errors } } = useForm()
  


  useEffect(() => {
    if (dataObj?.id) {
      setTimeout(() => {
        let data = {
          "id": dataObj?.id,
          "name": dataObj?.name,
          "email": dataObj?.email,
          "role": dataObj?.role,
          "allowedDomains": dataObj?.allowedDomains
        }
        reset(data);
      }, 200)

    }
  }, [dataObj])

  const onAddUser = async (data) => {
    console.log(data, "data")
    let requestBody = {
      "name": data?.name,
      "email": data?.email,
      "password": data?.password,
      "role": data?.role,
      "allowedDomains": data?.allowedDomains
    }
    try {
      let response = await createUser(requestBody)
      dispatch(pushSetUserListData(response?.data?.responseData?.createdUser))
      onHide()
      console.log(response, "response")
    } catch ({ response }) {

    }
  }

  const onUserUpdate = async (data) => {
    console.log(data, "data")
    let requestBody = {
      "id": data?.id,
      "name": data?.name,
      "email": data?.email,
      "password": data?.password,
      "role": data?.role,
      "allowedDomains": data?.allowedDomains
    }
    try {
      let response = await updateUser(requestBody)
      onHide();
      
      //dispatch(pushSetUserListData(response?.data?.responseData?.createdUser))
      
      console.log(response, "response")
    } catch ({ response }) {

    }
  }

  return (

    <>
      <Modal id="addExpertUser" className="modal fade" show={show} onHide={() => { onHide() }}>
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true">×</span></button>
          <h4 className="modal-title text-xs-center">{dataObj? "Update User" : "Add User"}</h4>
        </div>
        <div className="modal-body formLog">
          <div className="comman_response"> </div>
          <form onSubmit={handleSubmit(dataObj? onUserUpdate : onAddUser)}>
            <div className="form-group">
              <label className="control-label">Name</label>
              <div>
                <input type="text" className="form-control input-lg" name="name" {...register("name", {
                  required: {
                    value: true,
                    message: "Name is required"
                  }
                })} />
                <div className="error error_first_name" >{errors?.name?.message}</div>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label">E-Mail Address</label>
              <div>
                <input className="form-control input-lg" id="email" name="email"  {...register("email", {
                  required: {
                    value: true,
                    message: "Email is required"
                  }
                })} />
                <div className="error error_email" >{errors?.email?.message}</div>
              </div>
            </div>

            {
              !dataObj
                ?
                <div className="form-group">
                  <label className="control-label">Password</label>
                  <div>
                    <input type="password" className="form-control input-lg" name="password" {...register("password", {
                      required: {
                        value: true,
                        message: "Password is required"
                      }
                    })} />
                    <div className="error error_password" >{errors?.password?.message}</div>
                  </div>
                </div>
                :
                <></>
            }


            <div className="form-group">
              <label className="control-label"> Role</label>
              <div>
                <select name="role" className="form-control role"   {...register("role", {
                  required: {
                    value: true,
                    message: "Role is required"
                  }
                })}>
                  <option value={""}>Select Role</option>
                  <option value={USER_ROLE?.[0]?.value}>{USER_ROLE?.[0]?.label}</option>
                  <option value={USER_ROLE?.[1]?.value}>{USER_ROLE?.[1]?.label}</option>
                  <option value={USER_ROLE?.[2]?.value}>{USER_ROLE?.[2]?.label}</option>
                </select>
                <div className="error error_role">{errors?.role?.message}</div>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label">Allowed Domains</label>
              <div>
                <textarea className="form-control input-lg" name="password" {...register("allowedDomains")} />
                {/* <div className="error error_password" >{errors?.password?.message}</div> */}
              </div>
            </div>


            <div className="form-group">

              <button type='submit' className="btnblue saveUser loadingbtns btn btn-theme">Save Settings!</button>

            </div>
          </form>
        </div>
      </Modal>

    </>


  )
}

export default AddUser