import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { addTag } from '../../../Components/action/tags'
import { useDispatch } from 'react-redux'
import { PushAccountList } from '../../../redux/slices/admin'

function ManageRecommendedTagNew({ recommendedFor, show, onHide }) {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm()
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [showForm, setShowForm] = useState(true);

    useEffect(() => {

    }, []);
    const onUpdateTag = async (data) => {
        setSuccessMessage(false);
        setErrorMessage(false);
        if(recommendedFor != null){
            data = {...data, recommendedFor: recommendedFor}
        }

        console.log('ewewewewe', data);

        try {
            setSubmitting(true);
            let response = await addTag(data);
            console.log(response.data.message)
            setSubmitting(false)
            setSuccessMessage(response.data.message);
            setShowForm(false);
            reset(); 
            //onHide()
        } catch (error) {
            setSubmitting(false)
            setErrorMessage(error.response.data.message);
            setSubmitting(false)
        }
    }
    return (
        <Modal id="addExpertUser" className="modal fade" show={show} onHide={() => { onHide() }}>
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true">×</span></button>
                <h4 className="modal-title text-xs-center">Add Tag</h4>
            </div>
            <div className="modal-body formLog">
                <div className="comman_response"> </div>
                <form onSubmit={handleSubmit(onUpdateTag)}>
                    <div className="form-group" style={{ minWidth: '300px' }}>
                        {
                            successMessage ? <span className="success_message">{successMessage}</span> : errorMessage ? <span className="error_message">{errorMessage}</span> : <></>
                        }
                    </div>

                    {
                        showForm
                        ?
                        <>
                            <div className="form-group">
                                <label className="control-label">Tag</label>
                                <div>
                                    <input type="text" className="form-control input-lg" name="name" {...register("tag", {
                                        required: {
                                            value: true,
                                            message: "Tag is required"
                                        }
                                    })} />
                                    <div className="error error_first_name" >{errors?.tag?.message}</div>
                                </div>
                            </div>
                            <div className="form-group">
                                <button type='submit' className="btnblue saveUser loadingbtns btn btn-theme">
                                    {submitting ? <><i class="fa fa-circle-o-notch fa-spin"></i> Loading</> : "Save"}</button>
                            </div>
                        </>
                        :
                        null
                    }
                </form>
            </div>
        </Modal>
    )
}

export default ManageRecommendedTagNew


