import axios from "axios";


// fetch tags
export const fetchTags = (props) => {
    return axios({
        method: 'GET',
        url: '/tags/list',
        params: props
    })
}

//fetch parent tags
export const getParentTags = (props) => {
    return axios({
        method: 'GET',
        url: '/tags/getParentTags',
        params: props
    })
}

// fetch tags
export const fetchAllTags = (props) => {
    return axios({
        method: 'GET',
        url: '/tags/listAll',
        params: props
    })
}

// add tag
export const addTag = (payload) => {
    let data = {...payload};
    delete data?.id;
    return axios({
        method:  payload?.id ? 'PATCH': 'POST',
        url: `${'/tag'}${payload?.id ? "/"+payload?.id:""}`,
        data: data
    })
}

// add tag
export const approveTag = (id) => {
    return axios({
        method:  'PATCH',
        url: "/tag/approveRecommendedTag/"+id,
    })
}


export const deleteTag = (id) => {
    return axios({
        method: 'DELETE',
        url: '/tag/'+id
        
    })
}
