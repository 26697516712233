import React from "react";
import { useState } from "react";
import {
  accountDelete,
  domainDetails,
  fetchAccountList,
  getAccountById,
  editAccountDetail,
  postAccountId,
} from "../../Components/action/common";
import AccountEdit from "../Dashboard/Modal/AccountEdit";
import AddAccount from "../Dashboard/Modal/AddAccount";
import SideBar from "../Dashboard/SideBar";
import TopHeader from "../Dashboard/TopHeader";
import { useDispatch, useSelector } from "react-redux";
//import { setAccountList } from '../../redux/slices/admin'
import { useEffect } from "react";
import Pagination from "react-js-pagination";
import { authentication } from "../../Components/action/utilities";
import { useNavigate, Navigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { FaSyncAlt } from "react-icons/fa";
import toast from "react-hot-toast";

function AccountList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //const { accountList } = useSelector(state => state.admin)
  const [editAccountModal, setEditAccountModal] = useState(false);
  const [addAccount, setAddAccount] = useState(false);
  const [editAccountData, setEditAccountData] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [limits, setLimits] = useState(null);
  const [accountList, setAccountList] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [params, setParams] = useState({ pageNumber: 1 });
  const [timer, setTimer] = useState(null);

  const [refreshLoading, setRefreshLoading] = useState(false);
  const [refreshAccountId, setRefreshAccountId] = useState("");

  // console.log(accountList, "accountList")
  const fetchAccountListing = async (params) => {
    try {
      let response = await fetchAccountList(params);

      console.log(
        response?.data?.responseData?.data,
        "response?.data?.responseData?.data"
      );
      setAccountList(response?.data?.responseData);
    } catch ({ response }) {
      response.status === 401 && navigate("/");
      response.status === 401 && localStorage.clear();
    }
  };

  const fetchAccountDetailById = async (account) => {
    try {
      let response = await domainDetails({ id: account?.id });
      setEditAccountData(response?.data?.responseData);
    } catch ({ response }) {
      response.status === 401 && navigate("/");
      response.status === 401 && localStorage.clear();
    }
  };

  const onDeleteAccount = (account) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let response = await accountDelete({ id: account?.id });
              fetchAccountListing();
              console.log(response, "response");
            } catch ({ response }) {
              response.status === 401 && navigate("/");
              response.status === 401 && localStorage.clear();
            }
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const onSearchAccount = async (value) => {
    let updatedParams = Object.assign(params, {
      searchText: value,
      pageNumber: 1,
    });
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      fetchAccountListing(updatedParams);
    }, 500);
    setTimer(newTimer);
    setParams(updatedParams);
  };

  const handlePageChange = (pageNumber) => {
    let localParams = params;
    localParams = Object.assign(localParams, { pageNumber: pageNumber });
    setParams(localParams);
    fetchAccountListing(localParams);
  };

  useEffect(() => {
    fetchAccountListing();
  }, [activePage]);

  const updateAccountName = (index) => {
    let accountListArray = JSON.parse(JSON.stringify(accountList));

    accountListArray[index] = Object.assign(accountListArray[index], {
      updateName: true,
    });
    setAccountList(accountListArray);
  };

  const saveAccountName = async (name, id, index) => {
    if (name) {
      let accountListArray = JSON.parse(JSON.stringify(accountList));
      accountListArray[index] = Object.assign(accountListArray[index], {
        updateName: false,
      });
      setAccountList(accountListArray);
      let requestBody = {
        id: id,
        name: name,
      };
      try {
        let response = await editAccountDetail(requestBody);
      } catch ({ response }) {
        response.status === 401 && navigate("/");
        response.status === 401 && localStorage.clear();
      }
    }
  };

  const updateDomainLocally = async (name, index, account) => {
    let accountListArray = JSON.parse(JSON.stringify(accountList));
    accountListArray[index] = Object.assign(accountListArray[index], {
      publicDomainName: name,
    });
    setAccountList(accountListArray);
  };

  const updateDomainName = (index) => {
    let accountListArray = JSON.parse(JSON.stringify(accountList));

    accountListArray[index] = Object.assign(accountListArray[index], {
      updateDomain: true,
    });
    setAccountList(accountListArray);
  };

  const saveDomainName = async (name, id, index) => {
    if (name) {
      let accountListArray = JSON.parse(JSON.stringify(accountList));
      accountListArray[index] = Object.assign(accountListArray[index], {
        updateDomain: false,
      });
      setAccountList(accountListArray);
      let requestBody = {
        id: id,
        publicDomainName: name,
      };
      try {
        let response = await editAccountDetail(requestBody);
      } catch ({ response }) {
        response.status === 401 && navigate("/");
        response.status === 401 && localStorage.clear();
      }
    }
  };

  const updateNameLocally = async (name, index, account) => {
    let accountListArray = JSON.parse(JSON.stringify(accountList));
    accountListArray[index] = Object.assign(accountListArray[index], {
      name: name,
    });
    setAccountList(accountListArray);
  };

  /*const onEditAccountDetail = async (data) => {
    let requestBody = {
      "id": editAccountData?.id,
      "name": editAccountData?.name,
      "host": editAccountData?.host,
      "userName": data?.userName,
      "password": data?.password,
      "databaseName": "databaseName",
      "publicDomainName": "publicDomainName"
    }
    try {
      setSubmitting(true);
      let response = await editAccountDetail(requestBody)
      setSubmitting(false)
      //  dispatch(PushAccountList(requestBody))
       onHide()
    } catch ({ response }) {
      setSubmitting(false)

    }

  }*/

  if (!authentication()) return <Navigate to={"/"} />;

  console.log("dddddd", accountList);

  const accountRefresh = (id) => {
    setRefreshAccountId(id);
    setRefreshLoading(true);
    postAccountId(id);
    setTimeout(() => setRefreshLoading(false), 2000);
    setTimeout(
      () => toast.success("Refresh Account Successfully Scheduled"),
      2500
    );
  };

  return (
    <>
      {/* <TopHeader /> */}
      <SideBar />

      <div className="content-page">
        {/* Start content */}
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="container">
            {/* Page-Title */}
            {/* <div className="row">
              <div className="col-sm-8">
                <h4 className="page-title">
                  <font style={{ verticalAlign: 'inherit' }}>Account management</font>
                </h4>

              </div>

              
            </div> */}
            <div className="filter_options">
              <h4 className="page-title">
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                    Account management
                  </font>
                </font>
              </h4>
              {/* <div className="comman_response" /> */}
              <div className="filter_options_inner">
                {/*---Start Search User Form----------*/}
                {/* <div className="col-md-4"> */}
                <div className="form-group">
                  <form id="searchUserForm" className="searchUserForm">
                    <input type="hidden" name="_token" />
                    <input
                      style={{ padding: "3px 0px 5px 5px" }}
                      type="text"
                      className="form-control"
                      name="searchUser"
                      placeholder="Search"
                      onChange={(e) => onSearchAccount(e?.target?.value)}
                    />
                  </form>
                </div>
                {/* </div> */}
                {/* <div className="col-md-2" style = {{float: 'right'}}> */}
                <a
                  className="btn btn-theme addNewUser"
                  onClick={() => setAddAccount(true)}
                >
                  <font style={{ verticalAlign: "inherit" }}>Add Account</font>
                </a>
                {/* </div> */}
                {/*---End Search User Form----------*/}
              </div>
            </div>

            <div className="userlisting row">
              <div className="table-responsive custom-center-ui scroll-table">
                <table
                  id="loginUser"
                  className="display dataTable accountManagement"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th className="th-account-name">
                        <font style={{ verticalAlign: "inherit" }}>
                          Account Name
                        </font>
                      </th>
                      <th className="th-host">
                        <font style={{ verticalAlign: "inherit" }}>Host</font>
                      </th>
                      <th className="th-host">
                        <font style={{ verticalAlign: "inherit" }}>Domain</font>
                      </th>

                      <th className="th-action">
                        <font style={{ verticalAlign: "inherit" }}>Action</font>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountList?.data?.length == 0 && (
                      <tr>
                        <td colSpan={5}>'No Data Found!'</td>
                      </tr>
                    )}

                    {accountList?.data?.map((account, index) => {
                      return (
                        <tr rel="kW5" className="userInfo" id="usr_kW5">
                          <td className="user_unique_id">
                            <font style={{ verticalAlign: "inherit" }}>
                              {!account.updateName && (
                                <span
                                  onDoubleClick={(e) =>
                                    updateAccountName(index)
                                  }
                                >
                                  {account?.name}
                                </span>
                              )}
                              {account.updateName && (
                                <input
                                  type="text"
                                  value={account?.name}
                                  onChange={(e) =>
                                    updateNameLocally(
                                      e.target.value,
                                      index,
                                      account
                                    )
                                  }
                                  onBlur={(e) =>
                                    saveAccountName(
                                      e.target.value,
                                      account?.id,
                                      index
                                    )
                                  }
                                />
                              )}
                            </font>
                          </td>
                          <td className="user_role_text">
                            <font style={{ verticalAlign: "inherit" }}>
                              {account?.host}
                            </font>
                          </td>
                          <td className="user_role_text ">
                            <font style={{ verticalAlign: "inherit" }}>
                              {!account.updateDomain && (
                                <span
                                  // onDoubleClick={(e) => updateDomainName(index)}
                                  className="tag_wrapper"
                                >
                                  {/* {account?.publicDomainName} */}
                                 
                                  <a
                                  href={account?.publicDomainName}
                        target="_blank"
                       
                      >
                                  {account?.publicDomainName}

                      </a>

                                </span>
                              )}
                              {account.updateDomain && (
                                <input
                                  type="text"
                                  value={account?.publicDomainName}
                                  onChange={(e) =>
                                    updateDomainLocally(
                                      e.target.value,
                                      index,
                                      account
                                    )
                                  }
                                  onBlur={(e) =>
                                    saveDomainName(
                                      e.target.value,
                                      account?.id,
                                      index
                                    )
                                  }
                                />
                              )}
                            </font>
                          </td>
                          <td className="td-action">
                            <span className="action-span action-acc">
                              <img
                                src="./images/edit.png"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setEditAccountModal(true);
                                  fetchAccountDetailById(account);
                                }}
                              />
                              {/* <i className="fa fa-edit" onClick={() => { setEditAccountModal(true); fetchAccountDetailById(account) }}></i> */}
                              {/* <i className='fa fa-trash' onClick={() => onDeleteAccount(account)}></i> */}
                              <img
                                src="./images/delete.png"
                                style={{ cursor: "pointer" }}
                                onClick={() => onDeleteAccount(account)}
                              />
                              <span
                                style={{ color: "#D68CA1", cursor: "pointer" }}
                                onClick={() => accountRefresh(account?.id)}
                              >
                                <img
                                src="./images/refresh.png"
                                  className={
                                    refreshLoading &&
                                    refreshAccountId === account?.id
                                      ? "rotatepage"
                                      : ""
                                  }
                                />

                              </span>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {accountList?.totalPages > 1 && (
                <div style={{ textAlign: "center" }}>
                  <Pagination
                    activePage={parseInt(accountList?.page)}
                    itemsCountPerPage={accountList?.limit}
                    totalItemsCount={accountList?.totalRecords}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              )}

              <div className="row">
                <div style={{}} className="pagnations">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {editAccountModal && (
        <AccountEdit
          show={editAccountModal}
          onHide={() => setEditAccountModal(false)}
          editAccountData={editAccountData}
          callback={fetchAccountListing}
        />
      )}

      {addAccount && (
        <AddAccount
          show={addAccount}
          onHide={() => setAddAccount(false)}
          callback={fetchAccountListing}
        />
      )}
    </>
  );
}

export default AccountList;
