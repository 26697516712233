import React, { useState } from 'react'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { deleteComment, fetchComment, postComments } from '../../../Components/action/common'
import { useDispatch, useSelector } from 'react-redux'
import { setCommentList } from '../../../redux/slices/admin'
import moment from 'moment'
import { Rating } from 'react-simple-star-rating';
import { GiSheep } from "react-icons/gi";
import { confirmAlert } from 'react-confirm-alert'; // Import

function EditComments({ show, onHide, assessment, callback,params }) {
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm()
  const { fetchComments, loggedInData } = useSelector(state => state.admin)
  const [isLoder, setIsLoader] = useState(false)
  const [startRating, setStartRating] = useState(assessment?.starRating)
  const [sheepRating, setSheepRating] = useState(assessment?.sheepRating)
  const [editCommentObj, setEditCommentObj] = useState(null)
  const [editCommentIndex, setEditCommentIndex] = useState(null)


  const onCommentSubmit = async (values) => {
    setIsLoader(true)
    let requestBody = {
      "id": assessment?.id,
      "refUserId": assessment?.refUserId,
      "comments": values?.comments,
      "starRating": startRating,
      "sheepRating": sheepRating,
      "starRatingComment": values?.starRatingComment,
      "sheepRatingComment": values?.sheepRatingComment
    }
    if (editCommentIndex != null) {
      requestBody = Object.assign(requestBody, { id: editCommentObj?.id })
    }
    try {
      let response = await postComments(requestBody)
      // setEditCommentIndex(null);
      // setEditCommentObj(null);
      reset()
      // fetchCommentsList()
      setIsLoader(false)

      callback(params);
      onHide()

      // onHide()
    } catch ({ response }) {
      setIsLoader(false)
    }
  }

  

  const fetchCommentsList = async () => {
    console.log('77777777', assessment);
    setValue('comments', assessment?.comments);
    setValue('sheepRating', assessment?.sheepRating);
    setValue('sheepRatingComment', assessment?.sheepRatingComment);
    setValue('starRating', assessment?.starRating)
    setValue('starRatingComment', assessment?.starRatingComment)
  }

  useEffect(() => {
    fetchCommentsList()
  }, [])

  // console.log(startRating, 'loggedInData', sheepRating)
  return (
    <Modal className="modal right fade" show={show} onHide={() => { onHide() }}>
      <div className="modal-header" id = "comment_box">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true">×</span></button>
        <h4 className="modal-title text-xs-center">Remarks</h4>

      </div>
      <div className="modal-body formLog">
        <div className="comman_response" > </div>
        <form onSubmit={handleSubmit(onCommentSubmit)}>
          <div className="form-group">
            <textarea className='formStyle' {...register("comments", {
              required: {
                value: true,
                message: "Required"
              }
            })}></textarea>
            <div className="error error_email" >{errors?.comments?.message}</div>
          </div>
          <div className='form-group'>
            <h4 className="modal-title text-xs-center">Sheep rating <button type="button" className="button_link pull-right" onClick = {() => {
              setSheepRating(0);
              
            }}>Reset rating</button></h4>
            <Rating
              initialValue={sheepRating}
              fillIcon={<GiSheep size={50} color={"#000000"} />}
              emptyIcon={<GiSheep size={50} color={"#cccccc"} />}
              onClick={(r) => setSheepRating(r)}
            />
            <textarea className='formStyle' style={{ minHeight: "80px" }} {...register("sheepRatingComment")}></textarea>
          </div>
          <div className='form-group'>
            <h4 className="modal-title text-xs-center">Rating <button type="button" className="button_link pull-right" onClick = {() => {
              setStartRating(0);
              
            }}>Reset rating</button></h4>
            <Rating
              initialValue={startRating}
              onClick={(r) => setStartRating(r)}
            />
            <textarea className='formStyle' style={{ minHeight: "80px" }} {...register("starRatingComment")}></textarea>
          </div>
          <div className="form-group">
            {
              editCommentIndex != null &&
              <button type='button' className="btn btn-cancel " style={{marginRight: "10px"}} onClick = {() => {
                setValue('comments', "");
                setValue('sheepRating', 0);
                setValue('sheepRatingComment', "");
                setValue('starRating', 0)
                setValue('starRatingComment', "")
                setStartRating(0);
                setSheepRating(0);
                setEditCommentObj(null);
                setEditCommentIndex(null);
              }}>Cancel</button>
            }
            <button type='submit' className="btnblue saveUser loadingbtns btn btn-primary">{isLoder ? 'loading..' : editCommentIndex == null ? "Save" : "Update"}</button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default EditComments;