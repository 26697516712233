import React from 'react'
import { useNavigate } from 'react-router-dom'

function TopHeader() {
  const navigate=useNavigate()
  return (
    <div className="topbar">
    {/* LOGO */}
    <div className="topbar-left">
      {/* <div className="text-center"> */}
        <a  className="logo"><img className="img-responsive" src="./images/star.png" />
        {/* <span><font style={{verticalAlign: 'inherit'}}>TDB Speaker Agency</font></span> */}
        </a>
      {/* </div> */}
    </div>
    {/* Button mobile view to collapse sidebar menu */}
    {/* <div className="navbar navbar-default" role="navigation">
      <div className="container">
        <div>
          <div className="pull-left">
            <button className="button-menu-mobile open-left waves-effect">
              <i className="fa fa-bars" />
            </button>
            <span className="clearfix" />
          </div>
          <ul className="nav navbar-nav navbar-right pull-right">
            <li onClick={()=>{localStorage.clear();navigate('/')}}><a  className="waves-effect"><font style={{verticalAlign: 'inherit'}} >Logout</font></a></li>
          </ul>
        </div>
      </div>
    </div> */}
  </div>
  )
}

export default TopHeader