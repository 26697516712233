import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
    const navigate=useNavigate()
  return (
    <div className='page-not-found'>
    <div className='pagenotfound'> 
      <img src='/images/pagenotfound.png' alt=''/>
      <button onClick={()=>navigate('/')}>Go to Login Page</button>
      </div>

      </div>
  )
}

export default PageNotFound
