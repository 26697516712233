import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { addTag } from '../../../Components/action/tags'
import { useDispatch } from 'react-redux'
import { PushAccountList } from '../../../redux/slices/admin'
import Select from 'react-select';

function ManageTag({ show, onHide, callback,params, tagObject, parentTags }) {
    const dispatch = useDispatch()
    const { register, handleSubmit, setValue, formState: { errors } } = useForm()
    const [submitting, setSubmitting] = useState(false);
    const [parentTagId, setParentTagId] = useState(null);

    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    useEffect(() => {
        for(const [i, o] of parentTags.entries()){
            if(tagObject?.parentId == o.id){
                console.log(parentTags[i]);
                setParentTagId({id: parentTags[i]?.id, name: parentTags[i]?.name});
                break;
            }

        }
        if(tagObject){
            setValue('tag', tagObject?.name);
            setValue('id', tagObject?.id);
            setValue('parentId', tagObject?.parentId);
            
        }
    },[]);
    const onUpdateTag = async (data) => {
        try {
            setSubmitting(true);
            let response = await addTag(data)
            setSubmitting(false)
            dispatch(PushAccountList(response?.data?.responseData?.data))
            callback(params);
            onHide()
        } catch (error) {
            console.log('44444443333', error);
            setSubmitting(false)
            setErrorMessage(error.response.data.message);
        }
    }
    return (
        <Modal id="addExpertUser" className="modal fade" show={show} onHide={() => { onHide() }}>
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true">×</span></button>
                <h4 className="modal-title text-xs-center">Add Tag</h4>
            </div>
            <div className="modal-body formLog">
                <div className="comman_response"> </div>
                <form onSubmit={handleSubmit(onUpdateTag)}>

                <div className="form-group" style={{ minWidth: '300px' }}>
                    {
                        successMessage ? <span className="success_message">{successMessage}</span> : errorMessage ? <span className="error_message">{errorMessage}</span> : <></>
                    }
                </div>



                <div className="form-group" style={{ minWidth: '300px' }}>
                    {
                        tagObject && tagObject?.parentId != null
                        ?
                        <Select
                            options={parentTags}
                            getOptionLabel={(opt) => opt?.name}
                            getOptionValue={(opt) => opt?.id}
                            isClearable={true}
                            value={parentTagId}
                            onChange={(opt) => {
                                setParentTagId(opt || null);
                                setValue('parentId', opt?.id || null);
                            }}
                            isMulti={false}
                        />
                        :
                        !tagObject
                        ?
                        <Select
                            options={parentTags}
                            getOptionLabel={(opt) => opt?.name}
                            getOptionValue={(opt) => opt?.id}
                            isClearable={true}
                            onChange={(opt) => {
                                setValue('parentId', opt?.id || null);
                            }}
                            isMulti={false}
                        />
                        :
                        <></>
                    }

                    
                  
                </div>
                    <div className="form-group">
                        <label className="control-label">Tag</label>
                        <div>
                            <input type="text" className="form-control input-lg" name="name" {...register("tag", {
                                required: {
                                    value: true,
                                    message: "Tag is required"
                                }
                            })} />
                            <div className="error error_first_name" >{errors?.tag?.message}</div>
                        </div>
                    </div>
                    <div className="form-group">
                        <button type='submit' className="btnblue saveUser loadingbtns btn btn-theme">
                            {submitting ? <><i class="fa fa-circle-o-notch fa-spin"></i> Loading</> : "Save"}</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ManageTag


