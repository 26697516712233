import axios from "axios";


// fetch products
export const getProducts = (props) => {
    return axios({
        method: 'GET',
        url: '/getProducts',
        params: props
    })
}

export const deleteProduct = (id) => {
    return axios({
        method: 'DELETE',
        url: '/deleteProduct/'+id
        
    })
}

export const restoreProduct = (id) => {
    return axios({
        method:  'PATCH',
        url: "/restoreArchivedProduct/"+id,
    })
}

