import React from 'react'

function AuthHeader() {
  return (
    <nav className="navbar navbar-default navbar-static-top">
        <div className="container-fluid">
          <div className="navbar-header">
            {/* Collapsed Hamburger */}
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#app-navbar-collapse">
              <span className="sr-only">Toggle Navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            {/* Branding Image */}
            <a className="navbar-brand login_admin" >
              Assessment Tool
              {/* <img src="./OnlineSchool Admin_files/phpkbtCjFE2NR.jpg" width={130} height={50} alt="OKS Development" /> */}
            </a>
          </div>
          <div className="collapse navbar-collapse" id="app-navbar-collapse">
            {/* Right Side Of Navbar */}
            <ul className="nav navbar-nav navbar-right">
            </ul>
          </div>
        </div>
      </nav>
  )
}

export default AuthHeader