import React from "react";
import { useState } from "react";

import ManageTag from "../Dashboard/Modal/ManageTag";
import SideBar from "../Dashboard/SideBar";
import TopHeader from "../Dashboard/TopHeader";
import AssessmentEdit from '../Dashboard/Modal/AssessmentEdit';
import { useDispatch, useSelector } from "react-redux";
//import { setUnapprovedCommentsList } from '../../redux/slices/admin'
import { useEffect } from "react";
import Pagination from "react-js-pagination";
import { authentication } from "../../Components/action/utilities";
import EditComments from '../Dashboard/Modal/EditComments';
import { useNavigate, Navigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Rating } from 'react-simple-star-rating';
import { GiSheep } from "react-icons/gi";
import {approveComments, deleteUnapprovedComment } from "../../Components/action/common";
import Select from 'react-select';
import axios from "axios";
var CancelToken = axios.CancelToken;
var cancel;

function UnapprovedComments() {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [tagObject, setTagObject] = useState(null);
  const [showTagModal, setShowTagModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [parentTags, setParentTags] = useState([]);
  const [limits, setLimits] = useState(null);
  const [unapprovedCommentsList, setUnapprovedCommentsList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(null);

  const [totalRecords, setTotalRecords] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  const [commentModal, setCommentModal] = useState(false)
  const [assessmentDetails, setAssessmentDetails] = useState(null)

  const { loggedInData } = useSelector(state => state.admin);

  const [params, setParams] = useState({
    pageNumber: 1,
    
    
  });
  const [timer, setTimer] = useState(null);

  
  const fetchTagsListing = (params) => {
    if (typeof cancel == "function") {
      cancel();
    }
    axios({
      method: 'GET',
      params: params,
      url: "/getUnapprovedComments",
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })

    }).then((response) => {
      setUnapprovedCommentsList(response?.data?.responseData);
      setLimits(response?.data?.responseData?.limit);
      setTotalRecords(response?.data?.responseData?.totalRecords);
      setTotalPages(response?.data?.responseData?.totalPages);
      setCurrentPage(response?.data?.responseData?.page);
    })
  }

 

  const onDeleteComment = (obj) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let response = await deleteUnapprovedComment(obj.id);
              fetchTagsListing(params);
              console.log(response, "response");
            } catch ({ response }) {      response.status===401 && navigate("/")
      response.status===401 && localStorage.clear()

            }
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handlePageChange = (pageNumber) => {
    let localParams = JSON.parse(JSON.stringify(params));
    localParams = Object.assign(localParams, { pageNumber: pageNumber });
    setParams(localParams);
    fetchTagsListing(localParams);
  };

  useEffect(() => {
    fetchTagsListing(params);
  }, [activePage]);

  const fetchChildTags = (parentId) => {
    let localParams = JSON.parse(JSON.stringify(params));
    localParams = Object.assign(localParams, { parentId: parentId });
    setParams(localParams);
    fetchTagsListing(localParams);
  }

  const search = (value) => {

    let updatedParams = Object.assign(params, { searchText: value, pageNumber: 1 });
    if(!value){
      delete updatedParams.searchText;
    }
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      fetchTagsListing(updatedParams);
    }, 500)
    setTimer(newTimer)
    setParams(updatedParams);

  }

  const updateCommentStatus = async (id) => {
        try {
            let response = await approveComments(id);
            fetchTagsListing(params);
            //onHide()
        } catch ({response}) {
          response.status===401 && navigate("/")
      response.status===401 && localStorage.clear()


        }
  }



  if (!authentication()) return <Navigate to={"/"} />;


  return (
    <>
      {/* <TopHeader /> */}
      <SideBar />

      <div className="content-page">
        {/* Start content */}
        <div className="UserLoader" />
        <div className="content userpage">
          <div className="container">
            <div className="filter_options">
              <h4 className="page-title">
                <font style={{ verticalAlign: "inherit" }}>
                  Unapproved Comments
                </font>
              </h4>
              
            </div>
            <div className="filter_options">
              {
                params.parentId
                ?
                <button onClick = {() => {
                  let localParams = JSON.parse(JSON.stringify(params));
                  delete localParams.parentId;
                  setParams(localParams);
                  fetchTagsListing(localParams);
                }}>Back</button>
                :
                <></>
              }
            </div>

            <div className="userlisting row">
              <div className="table-responsive custom-center-ui tagManagment">
                <table
                  id="loginUser"
                  className="display dataTable accountManagement unapproved_comments_listing "
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      
                      <th className="th-account-name">
                        Author
                      </th>
                      <th className="th-account-name">
                        Comment
                      </th>

                      <th className="th-action">
                        
                          Actions
                        
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {unapprovedCommentsList?.data?.length == 0 && (
                      <tr>
                        <td colSpan={"100%"}>'No Data Found!'</td>
                      </tr>
                    )}
                    {unapprovedCommentsList?.data?.map((tag, index) => {
                      return (
                        <tr className="userInfo">
                          <td>
                            {(parseInt(currentPage) - 1) * parseInt(limits) +
                              (index + 1)}
                          </td>
                          
                          <td>{tag?.RefUser?.refUserName} [{tag?.RefUser?.refUserEmail}]</td>
                          <td className="user_unique_id" >
                            <p>{tag?.comments}</p>
                            <span className='star-rating'>
                              <Rating
                                initialValue={tag?.starRating}
                                readonly
                                size={25}
                              />
                            </span>

                            <p>{tag?.starRatingComment}</p>

                            <span className='star-rating'>
                              <Rating
                                initialValue={tag?.sheepRating}
                                fillIcon={<GiSheep size={25} color={"#000000"} />}
                                emptyIcon={<GiSheep size={25} color={"#cccccc"} />}
                                readonly
                              />
                            </span>

                            <p>{tag?.sheepRatingComment}</p>

                          </td>
                          
                          <td className="td-action">
                            <span className="action-span">
                              {
                                <button onClick={() => updateCommentStatus(tag.id)}>Approve</button>
                              }

                              
                              <img
                                src="./images/delete.png"
                                style={{ cursor: "pointer" }}
                                onClick={() => onDeleteComment(tag)}
                              />

                              <img title="Edit" src="./images/edit.png" style={{ cursor: "pointer" }} onClick={() => { setCommentModal(true); setAssessmentDetails(tag) }} />

                              {/* <i className="fa fa-edit" style={{ cursor: "pointer" }}></i> */}
                              {/* <i className='fa fa-trash' onClick={() => onDeleteComment(tag)}></i> */}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

              </div>
              {totalPages > 1 && (
                <div style={{ textAlign: "center" }}>
                  <Pagination
                    activePage={parseInt(params.pageNumber)}
                    itemsCountPerPage={limits}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              )}
              <div className="row">
                <div style={{}} className="pagnations">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        commentModal && (
          <EditComments
            show={commentModal}
            onHide={() => {
              setCommentModal(false)
            }}
            assessment={assessmentDetails}
            loggedInData={loggedInData}
            params={params}
            callback={fetchTagsListing}
          />
        )
      }


      {showTagModal && (
        <ManageTag
          parentTags={parentTags}
          show={showTagModal}
          onHide={() => {
            setShowTagModal(false);
            setTagObject(null);
          }}
          params={params}
          callback={fetchTagsListing}
          tagObject={tagObject}
        />
      )}
    </>
  );
}

export default UnapprovedComments;
