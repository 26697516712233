import * as Yup from "yup";


export const validationSchema = Yup.object().shape({
    refUserDigistoreId: Yup.string()
        .required("Required!"),
    refUserEmail: Yup.string()
        .required("Required!"),
    refUserName: Yup.string()
        .required("Required!"),
    refUserPicture: Yup.string()
        .required("Required!"),
  
});

  