import React from 'react'
import { Modal } from 'react-bootstrap'
import { deleteUser } from '../../../Components/action/common'

export default function DeleteUser({ show, onHide, fetchUserList, deletableData }) {

  console.log(deletableData,"deletableData")

  const onDeleteUser = async () => {
    console.log(deletableData?.id,"deletableData")
    try {
      let response = await deleteUser({ "id": deletableData?.id })
      fetchUserList()
      onHide()
    } catch ({ response }) {

    }
  }

  return (
    <Modal className="modala fade in" style={{ display: 'block', paddingLeft: '0px' }} show={show} onHide={() => { onHide() }}>

      <div className="modal-contenth" id="changePasswordPanel">
        <div className="modal-header" style={{border:"none"}}>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>×</font></font></span></button>
          <h4 className="modal-title" id="assignModalLabel"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Are you sure you want to delete this user?</font></font></h4>
        </div>
        <div >
          <div className="comman_response" />

          <div className="modal-footer"  style={{border:"none"}}>
            <button type="submit" className="btn btn-theme modelChangePasswordSave loadingbtns" onClick={() => onDeleteUser()}>Yes</button>
            <button type="button" className="btn btn-default" id="changePasswordCancel" onClick={onHide}><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>No</font></font></button>
          </div>
        </div>

      </div>
    </Modal>
  )
}
