import React from 'react'
import toast from 'react-hot-toast'
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { KONGRESS_ATUH_KEY, COPY_KONGRESS_END_POINT } from "../../../Components/Common/constants";
import StarRatings from 'react-star-ratings';
import { speakerDetail, deleteProduct, fetchDeletedProducts, restoreArchievedProduct, editNewAssesmentUser } from '../../../Components/action/common';
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select';
import _ from 'lodash';
import axios from 'axios';
import './style.css'
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { validationSchema } from "./editschema";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function EditNewExpertModal({ show, onHide, assessment, fetchAssessmentDetail, params, loggedInData }) {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [commentValue, setCommentValue] = useState(null)
    const [isLoader, setIsLoader] = useState(false)
    const [domainName, setDomainName] = useState('')
    const [formInitialValues, setFormInitialValues] = useState({})
    const [deletedProductsArray, setDeletedProductsArray] = useState([])
    const [fetchingData, setFetchingData] = useState({});
    const [apiUrl, setApiUrl] = useState('');
    const [domainError, setDomainError] = useState('');

    const isValidUrl = (str) => {
        if (/^(http(s?):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(str)) {
            console.log('YES');
            return true;
        } else {
            console.log('NO');
            return false;
        }
    }
    useEffect(() => {
        console.log(assessment?.refUserId, 'aaaaaa')
        fetchSpeakerDetail(assessment?.refUserId);
        fetchArchievedProducts();
    }, [])

    const fetchSpeakerDetail = async (params) => {
        
        try {
            let response = await speakerDetail(params)
            console.log(response?.data?.responseData, "response?.data?.responseData?.data")
            let data = response?.data?.responseData;
            data = Object.assign(data, {domainName: ""})
            setFormInitialValues(data);
            console.log('dddddd', data);
            setTimeout(() => {
                setFetchingData(false);
            }, 500)
            
        } catch ({ response }) {
            setFetchingData(false);
        }
    }

    const onSubmit = async (formData) => {
        console.log('====a===a==', formData);
        delete(formData?.id);
        delete(formData?.refUserPictureAll);
        delete(formData?.profileFreezed);
        delete(formData?.groupAccount);
        delete(formData?.createdAt);
        delete(formData?.updatedAt);
        delete(formData?.deletedAt);
        delete(formData?.RefUserProducts);
        delete(formData?.domainName);
        try {
            let response = await editNewAssesmentUser(formData, assessment?.id);
            setIsLoader(false);
            fetchAssessmentDetail(params);
            onHide();
        } catch ({ response }) {
          setIsLoader(false)
        }
        

    }





    const updateTags = (obj) => {
        setSelectedTags(obj);

    }

    const deleteUserProduct = async(obj, index) => {
        console.log(obj, 'ooooooo')
        try {
            let response = await deleteProduct(obj?.id)
            fetchSpeakerDetail(assessment?.refUserId);
            fetchArchievedProducts();
            
        } catch ({ response }) {
            setFetchingData(false);
        }
    }  
    const restoreProduct = async(obj, index) => {
        console.log(obj, 'ooooooo')
        try {
            let response = await restoreArchievedProduct(obj?.id)
            fetchSpeakerDetail(assessment?.refUserId);
            fetchArchievedProducts();
            
        } catch ({ response }) {
            setFetchingData(false);
        }
    }  
    
    const fetchArchievedProducts = async() => {
        try {
            let response = await fetchDeletedProducts({refUserId: assessment?.refUserId})
            setDeletedProductsArray(response?.data?.responseData);
        } catch ({ response }) {
            //setFetchingData(false);
            
        }
    }     


    console.log('selectedTags', deletedProductsArray)


    return (
        <Modal className="modal copy_modal" show={show} onHide={() => { onHide() }}>
            <div className="modal-lg">

                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>×</font></font></span></button>
                    <h4 className="modal-title" id="assignModalLabel"><font style={{ verticalAlign: 'inherit' }}><font style={{ verticalAlign: 'inherit' }}>Add new expert</font></font></h4>
                </div>
                <div className="modal-body formLog">
                    <div className="comman_response" />
                    <Formik
                        enableReinitialize={true}
                        initialValues={formInitialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, values, setFieldValue }) => {
                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-lg-6 form-group">
                                            <label className="control-label" htmlFor="refUserDigistoreId">Digistore ID</label>
                                            <Field
                                                id="refUserDigistoreId"
                                                name="refUserDigistoreId"
                                                placeholder="Jane"
                                                className="form-control"
                                            />
                                            {errors.refUserDigistoreId ? (
                                                <div className="error">{errors.refUserDigistoreId}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label className="control-label" htmlFor="lastName">Email</label>
                                            <Field
                                                id="refUserEmail"
                                                name="refUserEmail"
                                                className="form-control"
                                            />
                                            {errors.refUserEmail ? (
                                                <div className="error">{errors.refUserEmail}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 form-group">
                                            <label className="control-label" htmlFor="refUserDigistoreId">Title</label>
                                            <Field
                                                id="title"
                                                name="title"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label className="control-label" htmlFor="refUserName">Name</label>
                                            <Field
                                                id="refUserName"
                                                name="refUserName"
                                                className="form-control"
                                            />
                                            {errors.refUserName ? (
                                                <div className="error">{errors.refUserName}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <label className="control-label" htmlFor="refUserPicture">Photo</label>
                                            <Field
                                                id="refUserPicture"
                                                name="refUserPicture"
                                                className="form-control"
                                            />
                                            {errors.refUserPicture ? (
                                                <div className="error">{errors.refUserPicture}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <fieldset>
                                        <legend>Links</legend>


                                        <div className="row">
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="home_page_term">Home page term</label>
                                                <Field disabled={false} className="form-control" id="home_page_term" name="home_page_term" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="homePageLink">Home page link</label>
                                                <Field disabled={false} className="form-control" id="homePageLink" name="home_page_link" />
                                            </div>
                                        
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="newsletterTerm">News letter term</label>
                                                <Field disabled={false} className="form-control" id="newsletterTerm" name="newsletter_term" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="newsletterLink">News letter link</label>
                                                <Field disabled={false} className="form-control" id="newsletterLink" name="newsletter_link" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="freebieTerm">Freebie term</label>
                                                <Field disabled={false} className="form-control" id="freebieTerm" name="freebie_term" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label disabled={false} className="control-label" htmlFor="freebieLink">Freebie link</label>
                                                <Field disabled={false} className="form-control" id="freebieLink" name="freebie_link" />
                                            </div>
                                        
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="contactEmailTerm">Contact email term</label>
                                                <Field disabled={false} className="form-control" id="contactEmailTerm" name="contact_email_term" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="contactEmailAddress">Contact email</label>
                                                <Field disabled={false} className="form-control" id="contactEmailAddress" name="contact_email_address" />
                                            </div>
                                        </div>
                                    </fieldset>



                                    <fieldset>
                                        <legend>Social media links</legend>
                                        <div className="row">
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="facebookLink">Facebook link</label>
                                                <Field disabled={false} className="form-control" id="facebookLink" name="facebook_link" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="instagramLink">Instagram link</label>
                                                <Field disabled={false} className="form-control" id="instagramLink" name="instagram_link" />
                                            </div>
                                        
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="twitterLink">Twitter link</label>
                                                <Field disabled={false} className="form-control" id="twitterLink" name="twitter_link" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="youtubeLink">Youtube link</label>
                                                <Field disabled={false} className="form-control" id="youtubeLink" name="youtube_link" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="vimeoLink">Vimeo link</label>
                                                <Field disabled={false} className="form-control" id="vimeoLink" name="vimeo_link" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="pinterestLink">Pinterest link</label>
                                                <Field disabled={false} className="form-control" id="pinterestLink" name="pinterest_link" />
                                            </div>
                                        
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="xingLink">Xing link</label>
                                                <Field disabled={false} className="form-control" id="xingLink" name="xing_link" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="linkedinLink">Linkedin link</label>
                                                <Field disabled={false} className="form-control" id="linkedinLink" name="linkedin_link" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="telegramLink">Telegram link</label>
                                                <Field disabled={false} className="form-control" id="telegramLink" name="telegram_link" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="podcastLink">Podcast link</label>
                                                <Field disabled={false} className="form-control" id="podcastLink" name="podcast_link" />
                                            </div>
                                        
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="spotifyLink">Spotify link</label>
                                                <Field disabled={false} className="form-control" id="spotifyLink" name="spotify_link" />
                                            </div>
                                            <div className="col-lg-3 form-group">
                                                <label className="control-label" htmlFor="clubhouseLink">Clubhouse link</label>
                                                <Field disabled={false} className="form-control" id="clubhouseLink" name="clubhouse_link" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 form-group">
                                                <label className="control-label" htmlFor="tiktokLink">Tiktok link</label>
                                                <Field disabled={false} className="form-control" id="tiktokLink" name="tiktok_link" />
                                            </div>

                                        </div>
                                        

                                    </fieldset>
                                    {
                                        fetchingData == false &&
                                        <div className="row">
                                        <div className="col-lg-6 form-group">
                                            <label className="control-label" htmlFor="expert_short_description">Expert short description</label>
                                            <Field disabled={false} component="textarea" className="form-control" id="expert_short_description" name="expert_short_description" />
                                        </div>

                                        <div className="col-lg-6 form-group">
                                            <label className="control-label" htmlFor="expert_long_description">Expert long description</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={values?.expert_long_description}
                                                onReady={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    //console.log('Editor is ready to use!', editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    setFieldValue('expert_long_description', editor.getData())
                                                    //console.log(editor.getData());
                                                }}
                                                onBlur={(event, editor) => {
                                                    //console.log('Blur.', editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    //console.log('Focus.', editor);
                                                }}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    }
                                    
                                    <div className="row">
                                        <div className="col-lg-12 form-group ">
                                            <button type="submit" className="pull-right btn btn-primary loadingbtns">{isLoader ? 'Loading...' : 'Save'}</button>

                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>

            </div>

        </Modal>
    )
}

export default EditNewExpertModal

