import { createSlice } from "@reduxjs/toolkit"

const initialState={
    timeLogin:'',
    checkLog:false
}

const tokenTimeSlice=createSlice({
    name:'tokentimeslicename',
    initialState,
    reducers:{
        setTimeTokenLogin:(state,action)=>{
            state.timeLogin=action.payload
        },
        setTimeTokenLoginCheck:(state,action)=>{
            state.checkLog=action.payload
        },
        setTimeTokenLogOut:(state,action)=>{
            state.timeLogin=''
        }
    }
})

export const {setTimeTokenLogin,setTimeTokenLogOut,setTimeTokenLoginCheck} = tokenTimeSlice.actions;
export default tokenTimeSlice.reducer;