import React from 'react'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { editAccountDetail } from '../../../Components/action/common'
import { useDispatch } from 'react-redux'
import { PushAccountList } from '../../../redux/slices/admin'

function AccountEdit({ show, onHide, editAccountData, callback }) {
  console.log(editAccountData, "editAccountData")
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors }, setValue } = useForm()
  const [submitting, setSubmitting] = useState(false);

  const onEditAccountDetail = async (data) => {
    let requestBody = {
      "id": editAccountData?.id,
      "name": data?.name,
      "publicDomainName": data?.publicDomainName,
      "host": data?.host,
      "userName": data?.userName,
      "password": data?.password,
      "databaseName": data?.databaseName,

    }
    try {
      setSubmitting(true);
      let response = await editAccountDetail(requestBody)
      setSubmitting(false)
      //  dispatch(PushAccountList(requestBody))
      callback();
      onHide()
    } catch ({ response }) {
      setSubmitting(false)

    }

  }



  useEffect(() => {
    setValue("name", editAccountData?.name)
    setValue("publicDomainName", editAccountData?.publicDomainName)
  }, [editAccountData])
  return (
    <Modal id="addExpertUser" className="modal fade" show={show} onHide={() => { onHide() }}>
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}><span aria-hidden="true">×</span></button>
        <h4 className="modal-title text-xs-center">Edit Account</h4>
      </div>
      <div className="modal-body formLog">
        <div className="comman_response"> </div>
        <form onSubmit={handleSubmit(onEditAccountDetail)}>
        <div className="form-group">
            <label className="control-label">Account Name</label>
            <div>
              <input type="text" className="form-control input-lg" name="name" {...register("name", {
                required: {
                  value: true,
                  message: "Name is required"
                }
              })} />
              <div className="error error_first_name" >{errors?.name?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label">Public Domain</label>
            <div>
              <input className="form-control input-lg" id="publicDomainName" name="publicDomainName"  {...register("publicDomainName", {
                required: {
                  value: true,
                  message: "Public domain is required"
                }
              })} />
              <div className="error error_publicDomainName" >{errors?.publicDomainName?.message}</div>
            </div>
          </div>
          <fieldset className="custom_fieldset">
            <legend className='custom_legend'>Database settings</legend>
            <div className="form-group">
              <label className="control-label">Host</label>
              <div>
                <input className="form-control input-lg" id="host" name="host"  {...register("host", {
                  // required: {
                  //   value: true,
                  //   message: "Host is required"
                  // }
                })} />
                <div className="error error_email" >{errors?.host?.message}</div>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label">User</label>
              <div>
                <input autoComplete='off' className="form-control input-lg" id="email" name="userName"  {...register("userName", {
                  // required: {
                  //   value: true,
                  //   message: "user is required"
                  
                  // }
                })} />
                <div className="error error_email" >{errors?.userName?.message}</div>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label">Password</label>
              <div>
                <input type="password" className="form-control input-lg" name="password" {...register("password", {
                  // required: {
                  //   value: true,
                  //   message: "Password is required"
                  // }
                })} />
                <div className="error error_password" >{errors?.password?.message}</div>
              </div>
            </div>

            <div className="form-group">
              <label className="control-label">Database Name</label>
              <div>
                <input className="form-control input-lg" id="databaseName" name="databaseName"  {...register("databaseName", {
                  // required: {
                  //   value: true,
                  //   message: "Database name is required"
                  // }
                })} />
                <div className="error error_databaseName" >{errors?.databaseName?.message}</div>
              </div>
            </div>
          </fieldset>



          <div className="form-group">

            <button type='submit' className="btnblue saveUser loadingbtns btn btn-primary">
              {submitting ? <><i class="fa fa-circle-o-notch fa-spin"></i> Loading</> : "Save Settings!"}</button>

          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AccountEdit