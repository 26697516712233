import { combineReducers } from "@reduxjs/toolkit";

// slices imports

import AdminSlice from './admin'



// combine reducer
const MainReducer = combineReducers({

  admin: AdminSlice,

});

export const rootReducer = (state, action) => {
  if (action.type === "DESTROY_SESSION") {
    state = undefined
  }
  return MainReducer(state, action);
}